<template>
    <div style="width: 100%">
        <div class="user-info">
            <el-upload
                class="avatar-uploader"
                accept="image/jpeg,image/gif,image/png"
                action=""
                :auto-upload="false"
                :show-file-list="false"
                :on-change="uploadAvatar"
            >
                <img :src="imageUser ? imageUser : imageUrl" class="avatar" />
            </el-upload>
            <div class="user-mess">
                <div>
                    <span>用户ID:</span>
                    <span style="margin-left: 5px">{{ curUserId }}</span>
                </div>
                <div>
                    <span>我的邀请码:</span>
                    <span style="margin:0 5px">{{ curInviteCode }}</span>
                    <el-button type="text" v-clipboard:copy="curInviteCode" v-clipboard:success="onCopy">复制</el-button>
                </div>
                <div>
                    <span>{{ telephone }}</span>
                    <span>&emsp;欢迎，</span>
                    <span v-if="changeNameFlag == 0">
                        {{ userName }}
                        <i class="el-icon-edit" @click="toggleNameFlag(0)"></i>
                    </span>
                    <span v-if="changeNameFlag == 1">
                        <el-input
                            size="small"
                            placeholder="请输入新用户名"
                            v-model="newName"
                            class="input-new-name"
                            maxlength="20"
                            show-word-limit
                            ref="userNameInput"
                            @blur="toggleNameFlag(1)"
                            @change="changeName"
                        ></el-input>
                        <el-button
                            size="small"
                            type="primary"
                            @click="changeName"
                        >
                            确 定
                        </el-button>
                    </span>
                </div>
            </div>
        </div>
        <div v-if="loginId != 'personal'" class="colleague-info">
            <div class="header">
                <div
                    style="display: flex; align-items: center"
                    v-if="loginId != 'personal'"
                >
                    <el-image
                        style="width: 20px; height: 20px"
                        :src="imageYRZ"
                    ></el-image>
                    <span style="margin: 0 6px; font-size: 16px">
                        {{ loginId }}
                    </span>
                    <el-tag v-if="role" size="small">{{ role }}</el-tag>
                </div>
                <div
                    class="watch-more"
                    v-if="loginId != 'personal'"
                    @click="$router.push('/user/manage-colleague')"
                >
                    <span v-if="hasApply" class="new">新成员申请</span>
                    <i class="el-icon-s-unfold"></i>
                    <span style="margin-left: 10px">查看更多</span>
                </div>
            </div>
            <div v-if="loginId == 'personal'" class="img">
                <img
                    v-if="imageUrl2"
                    :src="imageUrl2"
                    style="width: 200px; height: 160px"
                />
                <p style="color: #ccc">进行企业认证，建立同事圈子</p>
            </div>
            <div v-else class="my-colleagues">
                <div
                    v-for="(item, index) in getCampany.users"
                    :key="index"
                    class="colleague"
                >
                    <img
                        v-if="index < 10"
                        :src="item.avatar ? item.avatar : imageUrl"
                        style="width: 80px; height: 80px"
                    />
                    <div
                        v-if="index < 10"
                        style="padding: 10px 0px; font-size: 14px"
                    >
                        {{ item.userName }}
                    </div>
                </div>
                <div
                    v-if="role == '管理员'"
                    class="colleague-add"
                    @mouseover="active = true"
                    @mouseleave="active = false"
                    @click="showAddDialog"
                >
                    <div
                        class="add el-icon-plus"
                        :style="[
                            active
                                ? {
                                      color: '#006aff',
                                      'border-color': '#006aff',
                                  }
                                : {},
                        ]"
                    ></div>
                    <div
                        :style="[
                            { padding: '10px 0px', 'font-size': '14px' },
                            active ? { color: '#006aff' } : {},
                        ]"
                    >
                        添加员工
                    </div>
                </div>
            </div>
        </div>
        <inviteNew-dialog
            v-if="addDialogVisible"
            ref="inviteNewDialog"
        ></inviteNew-dialog>
        <loginTimeout-dialog
            v-if="loginTimeoutDialogVisible"
            ref="loginTimeoutDialog"
        ></loginTimeout-dialog>
    </div>
</template>

<script>
import {
    getAllCompanyStaff,
    getStaffApplyList,
    updateUserInfo,
    uploadFile,
    userUpdateAva,
} from "@/api";
import { apiCallback } from "../../../../utils";
import inviteNewDialog from "../common/InviteNewDialog";
import loginTimeoutDialog from "../common/LoginTimeout";
export default {
    mame: "userInfo",
    data() {
        return {
            imageUrl: require("@/assets/img/icon_user.png"),
            imageUser: "",
            imageUrl2: require("@/assets/img/colleague.png"),
            imageYRZ: require("@/assets/img/login/authComIcon.png"),
            userName: "",
            newName: "",
            telephone: "",
            id: 0,
            changeNameFlag: 0,
            loginId: "", //登录身份
            getCampany: {}, //公司信息
            role: "", //公司身份
            companyId: -1, //公司id
            active: false, //添加员工鼠标移入移出 true：移入； false：移出
            addDialogVisible: false, //添加员工弹窗,
            loginTimeoutDialogVisible: false,
            hasApply: false, //是否有人申请加入企业
            pAssets: [
                {
                    prop: "0",
                    label: "赠送票",
                },
                {
                    prop: "0",
                    label: "充值票",
                },
                {
                    prop: "-",
                    label: "月卡",
                },
                {
                    prop: "0",
                    label: "积分",
                },
                {
                    prop: "-",
                    label: "会员信息",
                },
                {
                    prop: "0",
                    label: "海币余额",
                },
            ],
            cAssets: [
                {
                    prop: "0",
                    label: "充值票",
                },
                {
                    prop: "0",
                    label: "海币余额",
                },
            ],
        };
    },
    created() {},
    computed: {
        curUserId() {
            return this.$store.state.user.userId;
        },
        curInviteCode() { 
            return this.$store.state.user.inviteCode
        }
    },
    components: { inviteNewDialog, loginTimeoutDialog },
    mounted() {
        window.sessionStorage.setItem("activePath", "/user/userinfo");
        this.init();
    },
    methods: {
        async init() {
            this.loginId = this.$store.state.user.loginId;
            this.id = this.$store.state.user.userId;
            if (this.$store.state.user.userId) {
                this.userName = this.$store.state.user.username;
                this.telephone = this.$store.state.user.userphone;
                this.imageUser = this.$store.state.user.avatar;
                this.id = this.$store.state.user.userId;
            }
            this.hasApply = false;
            if (this.loginId != "personal") {
                this.getCompanyInfo();
            } else {
                this.getCampany = {};
                this.role = "";
            }
        },
        toggleNameFlag(showFlag) {
            if (!showFlag) {
                this.changeNameFlag = 1;
                this.newName = this.userName;
                this.$nextTick(function () {
                    this.$refs.userNameInput.focus();
                });
            } else {
                this.changeNameFlag = 0;
                this.newName = this.userName;
            }
        },
        changeName() {
            if (this.newName) {
                // this.userName = this.newName
                this.updateUserInfo(this.newName);
                this.newName = "";
            }
            this.changeNameFlag = 0;
        },
        beforeAvatarUpload(file) {
            const isJPG = file.type === "image/jpeg";
            const isLt2M = file.size / 1024 / 1024 < 2;

            if (!isJPG) {
                this.$message.error("上传头像图片只能是 JPG 格式!");
            }
            if (!isLt2M) {
                this.$message.error("上传头像图片大小不能超过 2MB!");
            }
            return isJPG && isLt2M;
        },
        //是否有人申请加入
        async getApplyInform(cid) {
            var that = this;
            var params = {};
            params.cid = cid;
            params.isaccess = 0;
            params.pagenum = 1;
            params.pagesize = 1;
            await getStaffApplyList(params)
                .then((res) => {
                    const data = apiCallback(res);
                    if (data.total > 0) {
                        that.hasApply = true;
                    }
                })
                .catch((err) => console.error(err));
        },
        //若不是个人登录，获取登录公司信息
        async getCompanyInfo() {
            var that = this;
            await getAllCompanyStaff({
                cname: this.loginId,
            })
                .then((res) => {
                    const data = apiCallback(res);
                    that.getCampany = {};
                    that.getCampany = data;
                    const { company, users } = data;
                    that.companyId = company.id;
                    // 企业管理员id
                    const uCompanyId=company.uid;
                    let idx = users.findIndex((item) => item.id == this.id);
                    if (idx != -1) {
                        if (users[idx].role == "admin") {
                            that.role = "管理员";
                        } else if (users[idx].role == "staff") {
                            if(uCompanyId===this.id){
                                that.role='企业管理员'
                            }else{
                                that.role='普通员工'
                            }
                        }
                    }
                    that.getApplyInform(company.id);
                })
                .catch((err) => {
                    console.error(err);
                });
        },
        //用户基本信息修改
        async updateUserInfo(name) {
            var that = this;
            await updateUserInfo({ name })
                .then(async (res) => {
                    that.$message.success("修改成功");
                    that.userName = name;
                    this.$store.commit("user/editUsername", name);
                })
                .catch((err) => console.error(err));
        },
        async uploadAvatar(file) {
            var that = this;
            let param = new FormData();
            param.append("file", file.raw);
            try {
                const { data: uploadData } = await uploadFile(param);
                if (uploadData.data?.url) {
                    userUpdateAva({
                        id: this.$store.state.user.userId,
                        avatar: uploadData.data.url,
                    })
                        .then((res) => {
                            var data = res.data;
                            if (data) {
                                that.$message.success("头像修改成功");
                                that.imageUser = uploadData.data.url;
                                this.$store.commit(
                                    "user/editAvatar",
                                    uploadData.data.url,
                                );
                            } else {
                                that.$message.error(data.msg);
                            }
                        })
                        .catch((err) => console.log(err));
                }
            } catch (err) {
                this.$message.error(err);
            }
        },
        showAddDialog() {
            this.addDialogVisible = true;
            this.$nextTick(() => {
                this.$refs.inviteNewDialog.init(this.companyId);
            });
        },
        onCopy() { 
            this.$message.success('复制成功')
        }
    },
};
</script>

<style lang="stylus" scoped>
/deep/input::-webkit-input-placeholder {
  color: #778899;
}

.el-breadcrumb {
  margin-bottom: 15px;
  /* margin-right: 150px; */
}

.user-info {
  display: flex;
  background-color: #ffffff;
  height: 120px;
  width: auto;
  justify-content: flex-start;
}

.user-mess {
  display: flex;
  flex-direction: column;
  padding: 8px 0;
  font-size: 14px;
  justify-content: space-around;
  margin-left: 16px;

  .el-card {
    border: 1px solid #006AFF;
    color: #006AFF;
  }
}

.colleague-info {
  margin-top: 20px;
  padding: 12px 16px 0 16px;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 16px;
  }

  .title {
    display: flex;
    justify-content: flex-start;
    font-size: 14px;
    font-weight: bold;
    margin-left: 4px;
  }

  .new {
    margin-right: 10px;
    padding: 5px 10px;
    border-radius: 4px;
    font-size: 12px;
    color: #fff;
    background: #006aff;
    box-shadow: 0 2px 10px rgba(0, 106, 255, 0.21);
    cursor: pointer;
  }

  .new:after {
    margin-right: 10px;
    position: absolute;
    right: 120px;
    transform: translateY(-130%);
    display: block;
    content: '';
    border: 5px solid transparent;
    border-left-color: #006aff;
  }

  .watch-more {
    display: flex;
    align-items: center;
    font-size: 16px;
    cursor: pointer;
    color: rgb(85, 114, 253);
    user-select: none;
  }

  .img {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .my-colleagues {
    display: flex;

    .colleague {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-right: 20px;
    }
  }

  .colleague-add {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 20px;

    .add {
      display: flex;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      width: 80px;
      height: 80px;
      color: #e5e5e5;
      font-size: 26px;
      border: 1px dashed #e5e5e5;
      border-radius: 4px;
      cursor: pointer;
    }
  }
}

.asset {
  margin-top: 20px;
  display: flex;
}

.person_asset {
  padding: 0 20px;
  height: 380px;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;

  .title {
    display: flex;
    justify-content: flex-start;
    font-size: 24px;
  }

  .context {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
  }
}

.company_asset {
  margin-left: 20px;
  padding: 0 20px;
  height: 380px;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;

  .title {
    display: flex;
    justify-content: flex-start;
    font-size: 24px;
  }

  .context {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
}

.input-new-name {
  width: 200px;
}

avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 120px;
  height: 120px;
  display: block;
}
</style>
